<template>
  <div class="page">
    <div
      v-if="zongheAllData && zongheAllData.length"
      class="flex justify-content align-content page"
    >
      <div
        class="flex zonghe align-items justify-content"
        v-for="(item, index) in zongheAllData"
        :key="'zongheAllData' + index"
      >
        <div>
          <img
            class="item-img"
            :src="require(`@/assets/images/vehicles/Grouping-${index+1}.png`)"
            alt=""
          />
        </div>
        <div class="flex flex-direction flex1 align-items">
          <div>
            <span class="value">{{ item.value }}</span>
            <!-- <dv-digital-flop style="height: 60px; width: 100%" :config="dvDigitalFlopConfig(item)" /> -->
            <span class="uuid">{{ item.uuid }}</span>
          </div>
          <div class="label">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { dataInterface } from "@/api/dataInterfaceApi";
import { fontSize36 } from './comm'
export default {
  components: {},
  data() {
    let { query } = this.$route;
    return {
      zongheAllData: [], // 综合累计
      query: query,
      timer: null
    };
  },
  mounted() {
    this.zongheAll();
    // this.timer = setInterval(() =>{
    //   this.zongheAll()
    // }, 5000)
  },
  methods: {
    dvDigitalFlopConfig(item = {}) {
      let counts = parseFloat(item.value || 0);
      let config = {
        number: [parseFloat(counts)],
        toFixed: 2,
        textAlign: item.textAlign || "center",
        // content: `{nt}${item.unit}`,
        style: {
          fill: item.color || "#fff",
          fontSize: item.fontSize || fontSize36,
          fontFamily: "DS-Digital-Bold",
        },
      };
      console.log("==config==", config);
      return config;
    },
    zongheAll() {
      let data = this.query;
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-综合态势lj",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, 'dsjoidsijdsfjiasdijojio')
          if (!res) return;
          let data = (res && res[0]) || {};
          this.zongheAllData.push({
            value: data.acc_exchange_vehicle_num,
            label: "累计充换电车辆",
            color: "#36FBFB",
            uuid: "辆",
          });
          this.zongheAllData.push({
            value: (parseFloat(data.acc_exchange_elect || 0) / 10000).toFixed(
              0
            ) + '万',
            label: "累计充换电电量",
            color: "#43FFB9",
            uuid: "kWh",
          });
          this.zongheAllData.push({
            value: (parseFloat(data.acc_exchange_mileage || 0) / 10000).toFixed(
              0
            ) + '万',
            label: "累计行驶里程",
            color: "#FFFFFF",
            uuid: "km",
          });
          this.zongheAllData.push({
            value: data.acc_power_exchange_num,
            label: "累计充换电次数",
            color: "#179DFF",
            uuid: "次",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  destroyed () {
      console.log('destroyed')
      // clearInterval( this.timer )
    },
};
</script>
  
<style lang="scss" scoped>
// @import "../scss/page.scss";
.page {
  width: 80%;
  height: 100%;
}
@import "@/assets/scss/comm.scss";

.zonghe {
  height: 100%;
  width: calc(80% / 4);
  .value {
    color: #87fff6;
    font-size: $FontSize64;
    @include ffamily-HT;
  }
  .uuid {
    font-size: $FontSize24;
  }
  .label {
    font-size: $FontSize24;
  }
}
</style>