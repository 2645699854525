<template>
  <div class="page">
            <MyTitle title="各电池类型平均变化速度" type="2" />
    <div class="page-1">
      <MyEchart
      class="myEchart"
      :visible="batteryTypeStatisticsShow"
      :id="'BatteryTypeStatistics'"
      :options="batteryTypeStatisticsOptions"
    />
    </div>
  </div>
</template>
  
  <script>
            import MyTitle from "@/views/components/MyTitle.vue";
import MyEchart from '@/views/components/charts/echarts'
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
    MyTitle
  },
  data() {
    let { query } = this.$route;
    return {
      batteryTypeStatisticsShow: false,
      batteryTypeStatisticsOptions: {},
      query: query,
    };
  },
  mounted() {
    this.batteryTypeStatistics();
  },
  methods: {
    batteryTypeStatistics() {
      let data = this.query;
      let params = {};
      let query = this.$route.query;
      params = {
        // enCode: this.batteryTypeList[this.batteryTypeAt].value, //'quanguo_shengshi-各电池类型平均能耗',
        enCode: "quanguo_shengshi-各电池类型平均变化速度",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      this.batteryTypeStatisticsShow = false;
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "各电池类型平均能耗");
          if (!res || !res.length) return;
          // 排序
          let data = res.sort(function (a, b) {
            return (
              new Date(a.soc_month.replace("-", "/")) -
              new Date(b.soc_month.replace("-", "/"))
            );
          });
          // let months = []
          let monthData = {};
          let batterys = [];
          data.map((item) => {
            if (!monthData[item.soc_month]) {
              monthData[item.soc_month] = [];
            }
            monthData[item.soc_month].push(item);
            // 所有电池类型
            if (
              !batterys.includes(
                item.battery_type || item.cat_model || item.city
              )
            ) {
              batterys.push(item.battery_type || item.cat_model || item.city);
            }
          });
          let xAxis = Object.keys(monthData);
          let seriesData = [];
          batterys.map((battery) => {
            let sData = {
              name: battery,
              type: "line",
              showSymbol: false,
              data: [],
            };
            seriesData.push(sData);
          });
          let arr = ['#94E5AE', '#7EE7FC','#FEFA82']
          // let arr1 = ['94E5AE', '7EE7FC','FEFA82']
          xAxis.map((item) => {
            seriesData.map((sData,index) => {
              let a =
                monthData[item].find((i) => {
                  return (
                    (i.battery_type || i.cat_model || i.city) == sData.name
                  );
                }) || {};
              sData.data.push(a.avg_change_speed || 0);
              sData.lineStyle = {
                color: arr[index],
              };
              // sData.areaStyle = {
              //     opacity: 0.8,
              //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         offset: 0,
              //         color: "rgba(79, 193, 239, 1)",
              //       },
              //       {
              //         offset: 1,
              //         color: "rgba(77, 194, 241, 0)",
              //       },
              //     ]),
              //   },
                sData.emphasis = {
                  focus: "series",
                }
                sData.smooth = true,
                sData.showSymbol = false
            })
          });
          this.batteryTypeStatisticsOptions = {


            title: {
              text: "km/min",
            },
            legend: {
              hidden: false,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                data: xAxis,
                // axisLabel: {
                //   rotate: 45 // 将标签逆时针旋转 90 度
                // }
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: seriesData,
          };
          this.batteryTypeStatisticsShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      console.log(color, "颜色");
      return color;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
</style>