<template>
  <div class="page">
     <MyTitle title="各城市日均充换电次数" type="2" />
    <div class="page-1">
      <MyEchart
        :visible="powerExchangeNumberShow"
        :id="'PowerExchangeNumber'"
        :options="powerExchangeNumberOptions"
      />
    </div>
  </div>
</template>

<script>
import MyTitle from "@/views/components/MyTitle.vue";
import MyEchart from "@/views/components/charts/echarts";
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
    MyTitle
  },
  data() {
    let { query } = this.$route;
    return {
      // 各城市单车日均换电次数
      powerExchangeNumberOptions: {},
      powerExchangeNumberShow: false,
      query: query,
    };
  },
  mounted() {
    this.powerExchangeNumber();
  },
  methods: {
    // 各城市单车日均换电次数
    powerExchangeNumber() {
      // let data = this.query;
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo-各城市单车日均充换电次数",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE": '000000',
        // (data ? data.province || data.city || data.adcode : "") || "000000",
        "@OPER_TYPE": "充换电",
      };
      dataInterface.dataFaceApi(params).then((res) => {
        console.log(res, "充换电");
        if (!res || !res.length) return;
        let data = res.sort(function (a, b) {
          return (
            parseFloat(b.per_vehicle_daily_exchange_num || 0) +
            parseFloat(b.per_vehicle_daily_charge_num || 0) -
            (parseFloat(a.per_vehicle_daily_exchange_num || 0) +
              parseFloat(a.per_vehicle_daily_charge_num || 0))
          );
        });
        let xAxisData = [];
        let seriesData1 = [];
        let seriesData2 = [];
        data.map((item) => {
          xAxisData.push(item.address);
          seriesData1.push(item.per_vehicle_daily_exchange_num || 0);
          seriesData2.push(item.per_vehicle_daily_charge_num || 0);
          // seriesData.push({
          // 	value: parseFloat(item.per_vehicle_daily_exchange_num, 10),
          // 	itemStyle: {
          // 		borderRadius: 5,
          // 	}
          // })
        });
        this.powerExchangeNumberOptions = {
          title: {
            text: "次",
            top: "3%",
            left: "2%",
            textStyle: {
              color: "#b3b3c9",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            right: "3%",
          },
          // grid: {
          // 	left: '3%',
          // 	right: '4%',
          // 	bottom: '3%',
          // 	containLabel: true
          // },
          xAxis: {
            type: "category",
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0, //使x轴文字显示全
            },
            data: xAxisData,
          },
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "日均换电次数",
              type: "bar",
              barWidth: 20,
              // stack: 'cheliangComparison',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#0DC2F0" },
                  { offset: 1, color: "rgba(11,67,83,0.1)" },
                ]),
              },
              data: seriesData1,
            },
            {
              name: "日均充电次数",
              type: "bar",
              barWidth: 20,
              // stack: 'cheliangComparison',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#16F3C8" },
                  { offset: 1, color: "rgba(22,62,56,0.1)" },
                ]),
              },
              data: seriesData2,
            },
          ],
        };
        this.powerExchangeNumberShow = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
</style>