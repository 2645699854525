<template>
  <div class="vehicles vehiclesPage">
    <!--  -->
    <div v-if="level == '000000'" class="aaaa" :class="{'hidden': !flag}" >      
      <mars/>
    </div>
    <vehicles3D v-show="!flag1"/>
    <div class="cover cover-home"></div>
    <MyHeader />
    <div class="page-left1 flex flex-direction">
      <MyTitle title="安全指标" />
      <div class="page-part1">
        <part4 />
      </div>
      <div class="page-part1">
        <part5 />
      </div>
      <div class="page-part1">
        <part6 />
      </div>
    </div>
    <!-- <div class="page-center">
            <div class="page-center-bottom">
                <part7/>
            </div>
        </div> -->
    <div class="page-right1 flex flex-direction" >
      <MyTitle title="能源指标" />
      <div class="page-part1">
        <part1 />
      </div>
      <div class="page-part1">
        <part2 />
      </div>
      <div class="page-part1">
        <part3 />
      </div>
    </div>
    <div class="footer">
      <part7 />
    </div>
  </div>
</template>

<script>
import MyTitle from "@/views/components/MyTitle.vue";
import part1 from "../components/vehiclesPart/Part_1.vue";
import part2 from "../components/vehiclesPart/Part_2.vue";
import part3 from "../components/vehiclesPart/Part_3.vue";
import part4 from "../components/vehiclesPart/Part_4.vue";
import part5 from "../components/vehiclesPart/Part_5.vue";
import part6 from "../components/vehiclesPart/Part_6.vue";
import part7 from "../components/vehiclesPart/Part_7.vue";
// import part8 from "../components/vehiclesPart/Part_8.vue";
import vehicles3D from "../../components/map/vehicles3D.vue";
import mars from "../../components/map/mars.vue";
import MyHeader from "@/views/components/MyHeader.vue";
export default {
  components: {
    part1,
    part2,
    part3,
    part4,
    part5,
    part6,
    part7,
    // part8,
    MyTitle,
    vehicles3D,
    mars,
    MyHeader,
  },
  data() {
    return {
      timer: null,
      timer1:null,
      flag: true,
      flag1: true,
      opacityShow: 10,
      level: ''
    };
  },
  created() {
    console.log(this.$route, '路由')
    this.level = this.$route.query.level || '000000'
    if(this.level != '000000'){
      this.flag1 = false;
    }
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.flag = false;
    }, 6*1000);
    if(this.level == '000000') {
      this.timer1 = setTimeout(() => {
      this.flag1 = false;
    }, 300);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
$HeaderHeight: 188px;
* {
  color: #fff;
}
.cover-home {
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/chonghuan/bg-2.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 3;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.vehicles {
  .aaaa {
    width: 3840px;
    height: 1592px;
    position: absolute;
    top: 0;
    transform: scale(1);
    transition: all 3s;
  }
  > .hidden {
    transform: scale(5);
    opacity: 0;
  }
}

.vehiclesPage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  .page-left1,
  .page-right1 {
    position: absolute;
    top: $HeaderHeight;
    height: calc(100% - #{$HeaderHeight});
    z-index: 3;
  }
  .page-left1,
  .page-right1 {
    width: 739px;
    .page-part1 {
      width: 100%;
      height: 440px !important;
    }
  }
  .page-left1 {
    left:50px;
  }
  .page-right1 {
    right: 50px;
  }
  .footer {
    width: 100%;
    position: absolute;
    bottom: 8%;
    left: 18%;
    z-index: 10;
  }
}
</style>