<template>
  <div class="page">
       <MyTitle title="24h充换电次数分布" type="2" />
    <div class="page-1">
      <MyEchart
      :visible="powerChangeTimeShow"
      :id="'PowerChangeTime'"
      :options="powerChangeTimeOptions"
    />
    </div>
  </div>
</template>
  
  <script>
    import MyTitle from "@/views/components/MyTitle.vue";
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
    MyTitle
  },
  data() {
    let { query } = this.$route;
    return {
      powerChangeTimeShow: false,
      powerChangeTimeOptions: {},
      query: query,
    };
  },
  mounted() {
    this.powerChangeTime();
  },
  methods: {
    // 换电次数时间分布
    powerChangeTime() {
      let data = this.query;
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-充换电次数时间分布",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
        "@OPER_TYPE": "充换电",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res || !res.length) return;
          // 时间排序
          let data = res.sort(function (a, b) {
            return a.cur_hour - b.cur_hour;
          });
          let xAxis = [];
          let seriesData1 = [];
          let seriesData2 = [];
          data.map((item) => {
            xAxis.push(item.cur_hour);
            seriesData1.push(item.exchange_num);
            seriesData2.push(item.charge_num);
          });
          this.powerChangeTimeOptions = {
            title: {
              text: "次",
            },
            color: ["#4fc1ef", "#8bcfd0"],
            legend: {
              right: "3%",
             
              // data: ["充电次数", "换电次数"],
            },
            tooltip: {
              trigger: "axis",
            },
            // tooltip: {
            //   trigger: "axis",
            //   axisPointer: {
            //     type: "shadow",
            //   },
            // },
            xAxis: {
              type: "category",
              data: xAxis,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "充电次数",
                type: "line",
                smooth: true,
                showSymbol: false,
                label: {
                  show: true,
                  position: "top",
                },
                lineStyle: {
                  width: 2,
                  color: "#4fc1ef",
                },
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(79, 193, 239, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(77, 194, 241, 0)",
                    },
                  ]),
                },
                tooltip: {
                  valueFormatter: function (value) {
                    return value + " 个";
                  },
                },
                emphasis: {
                  focus: "series",
                },
                data: seriesData2,
              },
              {
                name: "换电次数",
                type: "line",
                smooth: true,
                showSymbol: false,
                label: {
                  show: true,
                  position: "top",
                },
                lineStyle: {
                  width: 2,
                  color: "#8bcfd0",
                },
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(139, 207, 208, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(134, 201, 203, 0)",
                    },
                  ]),
                },
                tooltip: {
                  valueFormatter: function (value) {
                    return value + " 个";
                  },
                },
                emphasis: {
                  focus: "series",
                },
                data: seriesData1,
              },
            ],
          };
          this.powerChangeTimeShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
</style>