<!--
 * @Descripttion: 说明
 * @version: V1.0
 * @Author: SL
 * @Date: 2021-07-19 14:24:27
 * @LastEditors: SL
 * @LastEditTime: 2021-07-20 15:55:11
-->
<template>
  <div class="demo">
      <div id="container" ref="content"></div>
  </div>
</template>

<script>
import * as THREE from 'three'
// 鼠标控制器
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
export default {
  name: 'ThreeTest',
  data() {
      return {
          // 创建一个场景
          scene: null,
          // 创建一个相机
          camera: null,
          // 创建一个渲染器
          renderer: null,
          // 模型对象
          mesh: null,
          // 平面
          plane: null,
          // 点光源
          point: null,
          // step
          step: 0,
          controls: null
      }
  },
  methods: {
      // 初始化
      init() {
          // 初始化容器
          var content = this.$refs.content;
          // 创建一个场景
          this.scene = new THREE.Scene();
          this.scene.background = new THREE.Color("#000");
          // 创建几何体
          var geometry = new THREE.SphereGeometry(30, 50, 50);
          // 纹理加载器 （ 此处加载贴图 ）
          var texture = new THREE.TextureLoader().load(require('@/assets/images/vehicles/mars3.jpg'));
          // 几何体材质对象
          var material = new THREE.MeshLambertMaterial({
              map: texture
          });
          // 创建网格模型对象
          this.mesh = new THREE.Mesh(geometry, material);
          //设置几何体位置
          this.mesh.position.x = 0;
          this.mesh.position.y = 0;
          this.mesh.position.z = 0;
          this.scene.add(this.mesh);
          this.mesh.scale.set(1.2,1.2,1.2)

          // 创建点光源
          var point = new THREE.PointLight('#fff');
          point.position.set(40, 200, 30);
          this.point = point;
          this.scene.add(point);

          const sphereSize = 10;
          const pointLightHelper = new THREE.PointLightHelper(point, sphereSize);
          this.scene.add(pointLightHelper);

          //创建环境光
          var ambient = new THREE.AmbientLight(-1);
          this.scene.add(ambient);

          // 创建一个相机
          this.camera = new THREE.PerspectiveCamera(
              70,
              window.innerWidth / window.innerHeight,
              1,
              10000
          );
          this.camera.position.set(-50, 50, 50);
          this.camera.lookAt(0, 0, 0);

          // // 坐标轴辅助器，X,Y,Z长度30
        //   var axes = new THREE.AxesHelper(300);
        //   this.scene.add(axes);
          // // 辅助网格
        //   let gridHelper = new THREE.GridHelper(100, 100);
        //   this.scene.add(gridHelper);

        const width = 3840; // 设置宽度
        const height = 1590; // 设置高度
          // 创建渲染器
          this.renderer = new THREE.WebGLRenderer();
          this.renderer.setSize(width, height);
          this.renderer.setClearColor(0xb9d3ff, 1);
          //插入 dom 元素
          content.appendChild(this.renderer.domElement);
          console.log("1111",OrbitControls)
          this.controls = new OrbitControls(this.camera, this.renderer.domElement);
          
          this.controls.addEventListener("resize", this.render(), false);
      },
      render() {
          this.renderer.render(this.scene, this.camera);
          // 自动旋转动画
          this.mesh.rotateY(0.017);
          requestAnimationFrame(this.render);
      }
  },
  mounted() {
      this.init()
  }
}
</script>

<style scoped>

.demo {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#container {
  height: 100%;
  overflow: hidden;
}
</style>

