<template>
  <div class="page">
    <MyTitle title="SOC仿真平均变化时长" type="2" />
    <div class="page-1">
      <MyEchart
        :visible="tenDaysEmergencyShow"
        :id="'TenDaysEmergency'"
        :options="tenDaysEmergencyOptions"
      />
    </div>
  </div>
</template>
  
<script>
import MyTitle from "@/views/components/MyTitle.vue";
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
    MyTitle
  },
  data() {
    let { query } = this.$route
    return {
      tenDaysEmergencyShow: false,
      tenDaysEmergencyOptions: {},
      query: query
    };
  },
  mounted() {
    this.tenDaysEmergency();
  },
  methods: {
    // 近10日告警趋势
    tenDaysEmergency() {
      let data = this.query
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-soc仿真平均变化时长",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          console.log(res, 'soc仿真平均变化时长')
          let data = res;
          let xAxis = [];
          let yAxis = [];
          data.map((item) => {
            xAxis.push(item.soc);
            yAxis.push(item.avg_change_time);
          });
          this.tenDaysEmergencyOptions = {
            title: {
              text: "min",
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            legend: {
              right: "3%",
            },
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                data: xAxis,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                // name: "告警数量",
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                  width: 2,
                  // color: '#289CFF'
                },
                itemStyle: {
                  width: 2,
                  color: "#2A5CEC",
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.5,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#2A5CEC",
                    },
                    {
                      offset: 1,
                      color: "rgba(42,92,236,0)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: yAxis,
              },
            ],
          };
          this.tenDaysEmergencyShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
</style>