<template>
	<!-- <div id="container"></div> -->
	<amap :center.sync="center" :zoom="zoom" :style="{ opacity: opacityShow }">
		<amap-map-type :visible="visible" :showTraffic="false" :showRoad="false" :defaultType="1" />
		<div v-for="(item, index) in clusterData" :key="index">
			<div>

				<amap-marker  :position.sync="item.value" :anchor="anchor"
					:offset="[-100,-100]">
					<template v-if="item.datas > 0">
						<div class="mapBg flex flex-direction align-items justify-content-between"
							@click="clickArea(item)">
							<div class="marsbg flex flex-direction align-items justify-content">
								<div class="flex align-items">
									<div
										style="height: 30px; width: 125%; font-size: 25px; line-height: 20px;margin-left:45px">
										{{ item.name }}
									</div>
									<div style="height: 30px; width: 100%; line-height: 20px" class="flex">
										<div class="vehicleIconImg" style="margin-left:5px;"></div>
										<div class="itemNumber">{{ item.datas }}</div>
									</div>

								</div>
								<div></div>
							</div>
							<div class="marsblue"></div>
							<div class="marsbottom"></div>
						</div>
					</template>
				</amap-marker>
			</div>
		</div>
	</amap>
</template>

<script>
	// import AMapLoader from '@amap/amap-jsapi-loader'
	import {
		dataInterface
	} from "@/api/dataInterfaceApi";
	export default {
		components: {},
		data() {
			return {
				plugin: [{
					pName: "MapType",
					defaultType: 1, //0代表默认，1代表卫星
					showTraffic: false, // 路网
					showRoad: true, // 路况
				}, ],
				center: [110, 32],
				clusterData: [],
				anchor: "center",
				visible: true,
				params: {
					longitudeStart: "",
					latitudeStart: "",
					vehicleVin: "",
				},
				listDatas: [],
				car: {
					// path[0].slice(0)
					// pos: path[0].slice(0), // 小车icon的坐标，为空不能设置该key.
					// angle: (Math.atan2(dy, dx) * 180) / Math.PI,
					icon: {
						image: "https://jimnox.gitee.io/amap-vue/assets/car.png",
						imageSize: [26, 50],
					},

					offset: [-13, -25],
				},
				geoJson: {
					features: [],
				},
				zoom: 6,
				opacityShow: 0,
			};
		},
		created() {
			this.zoom = this.$route.query.zoom * 1 || 6;
			this.center = this.$route.query.center || [110, 32];
			let cityCode = this.$route.query.province ?
				this.$route.query.province :
				this.$route.query.city;
			console.log(this.$route.query.level, "级别");
			if (this.$route.query.level == "province") {
				console.log("当前-省");
				this.selectProvinceStationInfo(cityCode);
			} else if (this.$route.query.level == "city") {
				console.log("当前-市", cityCode);
				this.selectCityStationInfo(cityCode);
			} else if (this.$route.query.level == "district") {
				console.log("当前-区");
			} else {
				this.init();
			}
		},
		mounted() {},
		methods: {
			clickArea(item) {
				console.log(item)
				if (item.level == "province") {
					this.$router.push({
						path: "/vehicles-home",
						query: {
							province: item.cityCode,
							name: item.name,
							zoom: 8,
							level: item.level,
							center: item.value,
						},
					});
				} else if (item.level == "city") {
					this.$router.push({
						path: "/vehicles-home",
						query: {
							city: item.cityCode,
							name: item.name,
							zoom: 13,
							level: item.level,
							center: item.value,
							value: item.value,
						},
					});
				} else if (item.level == "district") {
					console.log(item, "最后一层了");
					this.$router.push({
						path: "/heatmap-home",
						query: {
							city: item.cityCode,
							name: item.name,
							// cityName: item.name,
							value: item.value,
							zoom: 13,
							level: 'district',
							center: item.value,
						},
					});
				}
			},
			init(city, vehicleVin) {
				let that = this;
				console.log(city);
				that.center = [110, 32];
				this.params.vehicleVin = vehicleVin ? vehicleVin : "";
				this.selectCountryStationInfo();
			},
			//获取全国站点数据
			selectCountryStationInfo() {
				let params = {
					enCode: "quanguo-各个省市区入网车辆数量", //TiDB-全国地图
					"@ADCODE": "000000",
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						setInterval(() => {
							this.opacityShow = 1;
						}, 2500);
						if (!res) return;
						console.log("车辆全国地图", res);
						// this.provinceStationInfo = res;
						// this.listDatas = res[0];
						this.getGeoJson(100000, "", res);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//获取省级站点数据
			selectProvinceStationInfo(updateParams) {
				console.log(updateParams, "updateParams");
				//获取省的站点信息
				let params = {
					enCode: "quanguo-各个省市区入网车辆数量", //TiDB-全国地图
					"@ADCODE": updateParams,
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						setInterval(() => {
							this.opacityShow = 1;
						}, 2500);
						if (!res) return;
						this.getGeoJson(updateParams, "province", res);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//获取区级站点数据
			selectCityStationInfo(updateParams) {
				console.log(updateParams, "城市数据22222222222222222222");
				//获取省的站点信息
				let params = {
					enCode: "quanguo-各个省市区入网车辆数量", //TiDB-全国地图
					"@ADCODE": updateParams,
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						setInterval(() => {
							this.opacityShow = 1;
						}, 2500);
						if (!res) return;
						console.log(res, " 区级4444444444444444444444444444444");
						this.getGeoJson(updateParams, "city", res);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getGeoJson(adcode, type, stationInfo) {
				console.log(stationInfo, '99999999900000000000000000000000000000000')
				// type： province||city
				let that = this;
				/* eslint-disable */
				AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
					var districtExplorer = new DistrictExplorer();
					districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
						console.log(adcode, '8888888888888888888888')
						if (error) {
							console.error(error);
							return;
						}
						let Json = areaNode.getSubFeatures();
						console.log(Json, '8888888888888888888888')
						if (Json.length > 0) {
							console.log(Json, "Json");
							that.geoJson.features = Json;
						} else if (Json.length === 0) {
							that.geoJson.features = that.geoJson.features.filter(
								(item) => item.properties.adcode == adcode
							);
							if (that.geoJson.features.length === 0) return;
						}
						that.getMapData(type, stationInfo);
					});
				});
			},
			//获取数据
			async getMapData(type, stationInfo) {
				let _this = this;

				let mapData = this.geoJson.features.map((item) => {
					//可以改造数据
					let data = {
						name: item.properties.name,
						//数据value 需要改
						datas: 0,
						position: item.properties.center,
						cityCode: item.properties.adcode,
						level: item.properties.level, //省 province ，市city ，区district
						value: item.properties.center,
					};
					let currentData = [];
					currentData = stationInfo.filter(
						(item) => (item.adcode || item.district || item.city) == data.cityCode
					);

					if (currentData && currentData.length > 0) {
						data.vehicles = currentData[0].cumulative_number_vehicles_network;
						data.datas = currentData[0].cumulative_number_vehicles_network;
						data.value = item.properties.center;
					}
					return data;
				});
				this.clusterData = mapData;
				console.log(this.clusterData, '数字人跳转后的数据11111111111')
			},
		},
		filters: {},
		// 销毁定时器
		beforeDestroy: function() {},
	};
</script>

<style>
	.amap-logo {
		display: none !important;
	}

	.amap-copyright {
		visibility: hidden !important;
	}
</style>

<style scope lang="scss">
	@import "@/assets/scss/comm.scss";

	.mapBg {
		width: 340px;
		height: 175px;

		.marsbg {
			width: 100%;
			height: 60px;
			background: url("~@/assets/images/vehicles/vehiclesBg.png");
			background-size: 100% 100%;

			.vehicleIconImg {
				width: 50px;
				height: 50px;
				background: url("~@/assets/images/comm/pin_car_color-1.png");
				background-size: 100% 100%;
				margin-top: -15px;
			}

			.itemNumber {
				margin-left: 20px;
				font-size: 48px;
				color: #00FDB1;
				@include ffamily-DS;
			}
		}

		.marsblue {
			width: 45px;
			height: 51px;
			background: url("~@/assets/images/vehicles/blue.png");
			background-size: 100% 100%;
			animation: Beating 1.2s ease infinite;
		}

		.marsbottom {
			width: 37px;
			height: 27px;
			background: url("~@/assets/images/vehicles/blueBottom.png");
			background-size: 100% 100%;
			animation: breath_light 3s ease infinite;
		}
	}

	.m-r-40 {
		margin-left: 25px;
	}

	.m-r-35 {
		margin-left: 35px;
	}

	.m-r-15 {
		margin-right: 15px;
	}

	.m-t-20 {
		margin-top: 20px;
	}

	.font-15 {
		font-size: 15px;
	}

	.font-20 {
		font-size: 18px;
	}

	.amap-container {
		background-color: transparent !important;
	}

	.amap-controls {
		display: none;
	}

	li.bge5 {
		background-color: #e5e5e5;
	}

	.marsMapBgAll1 {
		width: 188px;
		height: 138px;
		background: url("~@/assets/images/index/smallGreen.png") repeat;
		background-size: 100% 100%;
		position: relative;
		display: flex;
		padding-top: 30px;
	}

	.amap-icon {
		display: none;
	}

	.vehicleIcon::before {
		content: "";
		background: url("~@/assets/images/comm/vehicleIcon.jpg");
		background-size: 100% 100%;
		display: block;
		position: absolute;
		width: 20px;
		height: 19px;
		margin-right: 35px;
		left: 0;
	}
</style>

<style scoped>
	.baseInfo-body {
		position: absolute;
		/* bottom: 327px;
  left:262px; */
		/* bottom: 10px;
  left: 253px; */
		width: 100%;
		bottom: 0px;
		/* left: 310px; */
		z-index: 999;
	}

	/* .baseInfo-body2{
  position: relative;
  top: 423px;
  left:252px;
  z-index:999;
} */
	.detail-marker {
		position: absolute;
		top: -244px;
		left: 26px;
		width: 235px;
		border-radius: 5px;
		white-space: nowrap;
		padding: 0 10px;
		background-color: rgba(255, 255, 255, 0.2);
		background-color: rgba(0, 0, 0, 0.6);
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		/* text-align: center; */
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
		font-family: Roboto;
		/* border: 1px solid rgba(255, 255, 255, 100); */
	}

	.detail-marker .el-row {
		padding: 4px 2px;
	}

	.no-data {
		text-align: center;
		font-size: 15px;
		padding: 8px;
	}

	.marker-using-slot {
		position: absolute;
		top: -28px;
		left: -28px;
		border-radius: 3px;
		white-space: nowrap;
		padding: 0 10px;
		background-color: rgba(255, 255, 255, 0.2);
		background-color: rgba(255, 255, 255, 100);
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		text-align: center;
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
		font-family: Roboto;
		border: 1px solid rgba(255, 255, 255, 100);
	}

	.baseInfo-wrap {
		position: absolute;
		width: 100%;
	}

	.car-list li .el-row {
		padding: 2px 0;
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		text-align: left;
		font-family: SourceHanSansSC-regular;
	}

	.hidden-text {
		overflow: hidden;
		white-space: normal;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		width: 200px;
	}

	.car-list li {
		/* border: 1px solid red; */

		border-bottom: 1px solid #ccc;
	}

	.car-list li:hover {
		background-color: #ececec;
		cursor: pointer;
	}

	.list-change {
		padding-bottom: 10px;
	}

	.list-change .active {
		background-color: #fff;
	}

	.list-change span {
		display: inline-block;
		width: 130px;
		background-color: rgba(243, 246, 249, 100);
		color: rgba(56, 148, 255, 100);
		font-size: 14px;
		text-align: center;
		font-family: Roboto;
		padding: 10px;
		font-weight: bold;
		text-align: center;
		/* padding:10px;
  color: #3894FF;
  background-color:#fff; */
	}

	.statusText::before {
		content: "";
		position: relative;
		left: 6px;
		top: -4px;
		border: 1px solid #fddd74;
		background-color: #fddd74;
		display: inline-block;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin-right: 12px;
	}

	.statusText:nth-child(1)::before {
		border: 1px solid #76de79;
		background-color: #76de79;
	}

	.statusText:nth-child(3)::before {
		border: 1px solid grey;
		background-color: grey;
	}

	.statusText.offline::before {
		border: 1px solid grey;
		background-color: grey;
	}

	.statusText.static::before {
		border: 1px solid #fddd74;
		background-color: #fddd74;
	}

	.statusText.static {
		color: #000000 65%;
	}

	.statusText.online::before {
		border: 1px solid #76de79;

		background-color: #76de79;
	}

	.statusText.online {
		color: #000000 65%;
	}

	.total-wrap {
		display: flex;
		height: 94%;
		border: 1px solid #ccc;
	}

	.statusDescBar {
		position: relative;
	}

	.statusDescBar .top {
		position: absolute;
		top: 20px;
		display: flex;
		justify-content: space-around;
		left: 355px;
		z-index: 99;
		width: 180px;
		height: 30px;
		padding: 4px 10px;
		line-height: 30px;
		background-color: #fff;
		border-radius: 3px;
		box-shadow: 2px 2px 10px #909090;
		/* border: 1px solid red; */
		left: 418px;
		line-height: 28px;
		height: 33px;
		top: 24px;
	}

	.statusDescBar .top2 {
		position: absolute;
		top: 20px;
		display: flex;
		justify-content: space-around;
		left: 355px;
		z-index: 99;
		width: 180px;
		height: 30px;
		/* padding: 4px 10px; */
		line-height: 30px;
		/* background-color: #fff; */
		border-radius: 3px;
		box-shadow: 2px 2px 10px #909090;
		/* border: 1px solid red; */
		left: 418px;
		line-height: 28px;
		/* height: 33px; */
		top: 24px;
	}

	.title-l {
		/* width: 48px;
  height: 20px; */
		font-size: 12px;

		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.45);
		line-height: 20px;
		margin-bottom: 5px;
	}

	.side {
		width: auto;
		height: 100%;
		overflow-y: auto;
	}

	.main {
		flex: 1;
	}

	/* 图标区域 */
	.graph-wrap {
		/* position: absolute; */
		width: 420px;
		/* right: 0px; */
		/* z-index: 999; */
		height: 100%;
		overflow-y: auto;
		border: 1px solid #ccc;
	}

	.car-list {
		margin: 0;
		padding: 0 8px;
		list-style: none;
		width: 250px;
		height: 90%;
		position: relative;
	}

	.car-list li {
		box-sizing: border-box;
		width: 100%;
		padding: 8px;
		cursor: default;
	}

	.car-list li.hover,
	.car-list li.active {
		background-color: #e5e5e5;
	}

	.car-list .name {
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow-x: hidden;
	}

	.car-list .bar {
		padding: 4px 0 0;
	}

	.car-list .bar-inner {
		height: 8px;
	}

	.JNPF-common-layout-center .JNPF-common-layout-main {
		padding-bottom: 0;
	}

	.car-list-history {
		margin: 0;
		padding: 30px 8px;
		list-style: none;
		/* width: 250px; */
		width: 300px;
		height: 100%;
		position: relative;
	}

	.car-list-history li {
		box-sizing: border-box;
		width: 100%;
		padding: 8px;
		cursor: default;
		border-bottom: 1px solid #ccc;
		border-top: 1px solid #ccc;
	}

	.car-list-history li.hover,
	.car-list-history li.active {
		background-color: #e5e5e5;
	}

	.baseSty {
		text-align: center;
		height: 30px;
		line-height: 30px;
		color: #e5e5e5;
		margin-bottom: 20px;
	}

	.centerde {
		display: flex;
		align-items: center;
		color: #fff;
	}

	.newspa {
		margin: 0 2px;
	}
</style>