<template>
  <div class="page">
    <MyTitle title="各城市累计充换电次数" type="2" />
    <div class="page-1">
      <MyEchart
        :visible="powerExchangeNumberShow"
        :id="'CheliangSOC'"
        :options="powerExchangeNumberOptions"
      />
    </div>
  </div>
</template>
  
  <script>
import MyTitle from "@/views/components/MyTitle.vue";
import MyEchart from "@/views/components/charts/echarts";
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
    MyTitle,
  },
  data() {
    let { query } = this.$route;
    return {
      // 各城市车辆平均换下SOC
      powerExchangeNumberOptions: {},
      powerExchangeNumberShow: false,
      qeury: query,
    };
  },
  mounted() {
    this.powerExchangeNumber();
  },
  methods: {
    // 各城市车辆平均换下SOC
    powerExchangeNumber() {
      //   let data = this.query;
      let params = {};
      // let query = this.$route.query
      params = {
        enCode: "quanguo-各城市单车-ljchdcs",
        // '@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
        // '@ADCODE': '000000',
        // // (data ? data.province || data.city || data.adcode : '') || '000000',
        // '@OPER_TYPE':'充换电'
      };
      dataInterface.dataFaceApi(params).then((res) => {
		console.log("累计换电次数虚宿徐", res);
        if (!res || !res.length) return;
        // let data = res.sort(function(a, b) {
        // 	return (parseFloat(b.per_vehicle_daily_exchange_num || 0) + parseFloat(b.per_vehicle_daily_charge_num || 0))
        // 		- (parseFloat(a.per_vehicle_daily_exchange_num || 0) + parseFloat(a.per_vehicle_daily_charge_num || 0))
        // })
        let data = res || [];
        let xAxisData = [];
        // let seriesData1 = []
        // let seriesData2 = []
        let seriesData3 = [];
        let seriesData4 = [];
   
        console.log(data);
        data.map((item) => {
          xAxisData.push(item.address);
          seriesData3.push(item.exchange_num || 0); //累计换电次数
          seriesData4.push(item.charge_num || 0); //累计充电次数
        });
        this.powerExchangeNumberOptions = {
          title: {
            text: "次",
            top: "3%",
            left: "2%",
            textStyle: {
              color: "#b3b3c9",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          // grid: {
          // 	left: '3%',
          // 	right: '4%',
          // 	bottom: '3%',
          // 	containLabel: true
          // },
          xAxis: [
            {
              type: "category",
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0, //使x轴文字显示全
              },
              data: xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            //   {
            // 	name: '单车日均换电次数',
            // 	type: 'line',
            //   yAxisIndex: '1',
            // 	stack: 'cheliangComparison',
            // 	itemStyle: {
            // 		color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            // 			{ offset: 0, color: '#83bff6' },
            // 			{ offset: 1, color: '#188df0' }
            // 		])
            // 	},
            // 	data: seriesData1
            // },
            // {
            // 	name: '单车日均充电次数',
            // 	type: 'line',
            //   yAxisIndex: '1',
            // 	smooth: true,
            // 	itemStyle: {
            // 		color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            // 			{ offset: 0, color: '#3DCAFF' },
            // 			{ offset: 1, color: '#00FFD6' }
            // 		])
            // 	},
            // 	data: seriesData2
            // },
            {
              name: "累计换电次数",
              type: "bar",
              barWidth: 20,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 1, color: "#188df0" },
                ]),
              },
              data: seriesData3,
            },
            {
              name: "累计充电次数",
              type: "bar",
              barWidth: 20,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#3DCAFF" },
                  { offset: 1, color: "#00FFD6" },
                ]),
              },
              data: seriesData4,
            },
          ],
        };
        this.powerExchangeNumberShow = true;
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
</style>